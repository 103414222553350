export enum ActionTypes {
  OPEN_CLOSE_SIDE_BAR = "OPEN_CLOSE_SIDE_BAR",
  SET_LOADING = "SET_LOADING",
  SET_LOGGED_IN_USER_PROFILE_DETAILS = "SET_LOGGED_IN_USER_PROFILE_DETAILS",

  // ***** HOTEL VENDOR: STATE SET ACTION TYPE *****//
  SET_VENDOR_PROFILE_DETAILS = "SET_VENDOR_PROFILE_DETAILS",
  SET_HOTEL_DETAILS = "SET_HOTEL_DETAILS",
  SET_HOTEL_INFO = "SET_HOTEL_INFO",
  SET_HOTEL_CONTACTS = "SET_HOTEL_CONTACTS",
  SET_HOTEL_ROOMS = "SET_HOTEL_ROOMS",
  SET_HOTEL_MEAL_ALLOWANCE = "SET_HOTEL_MEAL_ALLOWANCE",
  SET_HOTEL_AMENITIES = "SET_HOTEL_AMENITIES",
  SET_HOTEL_EMPLOYEES = "SET_HOTEL_EMPLOYEES",

  // ***** END HOTEL VENDOR: STATE SET ACTION TYPE *****//

  // ***** TRANSPORT VENDOR: STATE SET ACTION TYPE *****//
  SET_TRANSPORT_INFO = "SET_TRANSPORT_INFO",
  SET_TRANSPORT_DETAILS = "SET_TRANSPORT_DETAILS",
  SET_TRANSPORT_AMENITIES = "SET_TRANSPORT_AMENITIES",
  SET_TRANSPORT_EMPLOYEES = "SET_TRANSPORT_EMPLOYEES",
  SET_TRANSPORT_VEHICLES = "SET_TRANSPORT_VEHICLES",
  SET_TRANSPORT_CONTRACT = "SET_TRANSPORT_CONTRACT",

  // ***** MEET GREET VENDOR: STATE SET ACTION TYPE *****//
  SET_MEET_GREET_INFO = "SET_MEET_GREET_INFO",
  SET_MEET_GREET_DETAILS = "SET_MEET_GREET_DETAILS",
  SET_MEET_GREET_AMENITIES = "SET_MEET_GREET_AMENITIES",
  SET_MEET_GREET_EMPLOYEES = "SET_MEET_GREET_EMPLOYEES",
  SET_MEET_GREET_VEHICLES = "SET_MEET_GREET_VEHICLES",
  SET_MEET_GREET_CONTRACT = "SET_MEET_GREET_CONTRACT",

  // ***** TRANSPORT HOTEL VENDOR: STATE SET ACTION TYPE *****//

  SET_VENDOR_REQUEST_COUNT = "SET_VENDOR_REQUEST_COUNT",
  HIDE_LEFT_NAVBAR = "HIDE_LEFT_NAVBAR",
  RESET_STATE = "RESET_STATE",
}
