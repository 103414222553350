import { Alert, Tab, Tabs } from "@mui/material";
import { useHotelVendorProfile } from "../../../hooks";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ITab } from "../VendorInfoTypes";

export const HotelContainer = () => {
  const { tabs, tab, handleTabChange, isLoading, error } =
    useHotelVendorProfile();
  return (
    <>
      {!isLoading && error && (
        <Alert severity="error">
          Some thing went wrong while fetching vendor info!
        </Alert>
      )}
      <div className="tabs-back-buttons">
        <div className="tabs-data">
          <Tabs
            value={tab.value}
            aria-label="lab API tabs example"
            onChange={(_undefined, value) => handleTabChange(value)}
          >
            {tabs.map((tabData: ITab, index: number) => {
              return (
                <Tab
                  key={`${index}${tabData.value}`}
                  value={tabData.value}
                  label={
                    <div className="tab-label">
                      <CheckCircleIcon
                        className={
                          tabData.completeStatus ? "completed" : "not-completed"
                        }
                      />
                      <span>{tabData.label}</span>
                    </div>
                  }
                />
              );
            })}
          </Tabs>
        </div>
      </div>
    </>
  );
};
