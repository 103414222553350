import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";

// Internal
import { ActionTypes, GlobalContextData } from "../context";
import { useDocumentTitle } from "./useDocumentTitle";
import { API } from "../constants";
import { ITab } from "../pages/VendorProfileDetails/VendorInfoTypes";
import { useGetService } from "./useGetService";
import { RoutePath } from "../routes";

const meetGreetTabsData: ITab[] = [
  {
    id: "profile",
    label: "Profile details",
    value: 0,
    completeStatus: false,
  },
  {
    id: "contract-information",
    label: "Contract Information",
    value: 1,
    completeStatus: false,
  },
  {
    id: "amenities",
    label: "Amenities",
    value: 2,
    completeStatus: false,
  },
  {
    id: "employees",
    label: "Employees",
    value: 3,
    completeStatus: false,
  },
  {
    id: "vehicles",
    label: "Vehicles",
    value: 4,
    completeStatus: false,
  },
];

export const useTransportationMeetGreetProfile = () => {
  useDocumentTitle("Transportation Meet & Greet");
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [tabs, setTabs] = useState(_.cloneDeep(meetGreetTabsData));
  const [tab, setTab] = useState<ITab>(tabs[0]);

  // Global store using context
  const { state, dispatch } = useContext(GlobalContextData);

  const { response, isLoading, error } = useGetService(
    id &&
      `${API.TRANSPORTATION_MEET_GREET.TRANSPORTATION_GET_MEET_GREET_DETAILS_BY_VENDORID}/${id}`
  );

  console.log("useTransportationMeetGreetProfile", state, response);

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_LOADING,
      payload: {
        isLoading: isLoading,
        loadingText: "Please wait...",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (response && Object.keys(response).length > 0) {
      dispatch({
        type: ActionTypes.SET_MEET_GREET_INFO,
        payload: response,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    const data = [...tabs];

    if (state?.meetGreetInfo?.meetGreetDetails?.id) {
      data[0].completeStatus = true;
    }
    if (state?.meetGreetInfo?.contract?.typeOfServices?.length > 0) {
      data[1].completeStatus = true;
    }
    if (
      state?.meetGreetInfo?.amenities &&
      state?.meetGreetInfo?.amenities.length > 0
    ) {
      data[2].completeStatus = true;
    }
    if (
      state?.meetGreetInfo?.vendorEmployees &&
      state?.meetGreetInfo?.vendorEmployees?.items?.length > 0
    ) {
      data[3].completeStatus = true;
    }

    if (
      state?.meetGreetInfo?.vehicles &&
      state?.meetGreetInfo?.vehicles?.length > 0
    ) {
      data[4].completeStatus = true;
    }

    setTabs(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  //   useEffect(() => {
  //     return () => {
  //       dispatch({
  //         type: ActionTypes.SET_MEET_GREET_INFO,
  //         payload: {
  //           hotelDetails: {},
  //           contacts: [],
  //           mealAllowance: [],
  //           rooms: [],
  //         },
  //       });
  //     };
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  useEffect(() => {
    const tabInfo = tabs.find(
      (tabData: ITab) => tabData.id === location.pathname.split("/")[2]
    );
    dispatch({
      type: ActionTypes.HIDE_LEFT_NAVBAR,
      payload: true,
    });
    tabInfo && setTab(tabInfo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleTabChange = (clickedTab: number) => {
    const tabInfo = tabs.find((tabData: ITab) => tabData.value === clickedTab);
    tabInfo && setTab(tabInfo);
    navigate(`/transportation-meet-greet/${tabInfo?.id}/${id}`);
  };

  const handleBack = () => {
    dispatch({
      type: ActionTypes.HIDE_LEFT_NAVBAR,
      payload: false,
    });
    navigate(RoutePath.DASHBOARD);
  };

  return {
    id,
    location,
    navigate,
    handleTabChange,
    handleBack,
    isLoading,
    error,
    tabs,
    tab,
  };
};
