import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";

// Internal
import { RoutePath } from "../../routes";
import { getUserData } from "../../shared/utils";

// Style
import "./VendorProfileDetails.scss";
import { VendorTypeService } from "../../constants";
import { TransportContainer } from "./TransportDetails/TransportContainer";
import { HotelContainer } from "./HotelDetails/HotelContainer";
import { MeetGreetContainer } from "./MeetGreetDetails/MeetGreetContainer";
import { TransportationMeetGreetContainer } from "./TransportationMeetGreetDetails/TransportationMeetGreetContainer";

type VendorProfileDetailsType = {
  children: React.ReactNode;
};

export const VendorProfileDetails = (props: VendorProfileDetailsType) => {
  // const { tabs, tab, handleTabChange, isLoading, error } =
  //   useDisplayVendorDetails();

  const vendorType = getUserData()["custom:vendorType"];

  return (
    <section className="vendor-details-container">
      <Link className="link-css" to={RoutePath.DASHBOARD}>
        <ArrowBackIosIcon className="back-icon" />
        Dashboard
      </Link>

      {vendorType === VendorTypeService.TRANSPORTATION_ONLY && (
        <TransportContainer />
      )}

      {vendorType === VendorTypeService.HOTEL_ONLY && <HotelContainer />}

      {vendorType === VendorTypeService.MEET_GREET && <MeetGreetContainer />}

      {vendorType === VendorTypeService.TRANSPORTATION_MEET_GREET && (
        <TransportationMeetGreetContainer />
      )}

      {/* <div className="tabs-back-buttons">
        <div className="tabs-data">
          <Tabs
            value={tab.value}
            aria-label="lab API tabs example"
            onChange={(_undefined, value) => handleTabChange(value)}
          >
            {tabs.map((tabData: ITab, index: number) => {
              return (
                <Tab
                  key={`${index}${tabData.value}`}
                  value={tabData.value}
                  label={
                    <div className="tab-label">
                      <CheckCircleIcon
                        className={
                          tabData.completeStatus ? "completed" : "not-completed"
                        }
                      />
                      <span>{tabData.label}</span>
                    </div>
                  }
                />
              );
            })}
          </Tabs>
        </div>
      </div> */}

      <div className="form-section">{props.children}</div>
    </section>
  );
};
