import React, { createContext, useReducer } from "react";

// Internal
import { ActionTypes } from "./GlobalActionTypes";
import {
  IHotelInfoTypes,
  IMeetGreetInfoTypes,
  ITransportInfoTypes,
} from "../pages/VendorProfileDetails/HotelDetails";

const initialState = {
  openSidebar: true,
  userInfo: null,
  hideLeftNavbar: false,
  hotelInfo: {
    hotelDetails: {},
    contacts: [],
    mealAllowanceDetails: {
      hotelCuisine: {},
      mealAllowance: [],
    },
    rooms: [],
  },
  transportInfo: {
    transportDetails: {},
  },
  meetGreetInfo: {
    meetGreetDetails: {},
  },
  loadingState: {
    isLoading: false,
    loadingText: "",
  },
  vendorRequestCount: "0",
};

interface InitialStateType {
  loadingState: { isLoading: boolean; loadingText: string };
  openSidebar: boolean;
  userInfo: any;
  hotelInfo: IHotelInfoTypes;
  transportInfo: ITransportInfoTypes;
  meetGreetInfo: IMeetGreetInfoTypes;
  vendorRequestCount: string;
  hideLeftNavbar: boolean;
}

const globalReducer = (
  state: any,
  action: {
    payload: any;
    type: any;
  }
) => {
  switch (action.type) {
    case ActionTypes.OPEN_CLOSE_SIDE_BAR:
      return {
        ...state,
        openSidebar: action.payload,
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loadingState: action.payload,
      };
    case ActionTypes.SET_LOGGED_IN_USER_PROFILE_DETAILS:
      return {
        ...state,
        userInfo: action.payload,
      };

    // ***** HOTEL VENDOR: STATE SET DATA *****//
    case ActionTypes.SET_HOTEL_INFO:
      return {
        ...state,
        hotelInfo: action.payload,
      };
    case ActionTypes.SET_HOTEL_DETAILS:
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          hotelDetails: action.payload,
        },
      };
    case ActionTypes.SET_HOTEL_CONTACTS:
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          contacts: action.payload,
        },
      };
    case ActionTypes.SET_HOTEL_ROOMS:
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          rooms: action.payload,
        },
      };
    case ActionTypes.SET_HOTEL_MEAL_ALLOWANCE:
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          mealAllowanceDetails: action.payload,
        },
      };
    case ActionTypes.SET_HOTEL_AMENITIES:
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          amenities: action.payload,
        },
      };

    case ActionTypes.SET_HOTEL_EMPLOYEES:
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          vendorEmployees: action.payload,
        },
      };

    // ***** END HOTEL VENDOR: STATE SET DATA *****//

    // ***** TRANSPORT VENDOR: STATE SET DATA *****//

    case ActionTypes.SET_TRANSPORT_INFO:
      return {
        ...state,
        transportInfo: action.payload,
      };

    case ActionTypes.SET_TRANSPORT_DETAILS:
      return {
        ...state,
        transportInfo: {
          ...state.transportInfo,
          transportDetails: action.payload,
        },
      };

    case ActionTypes.SET_TRANSPORT_CONTRACT:
      return {
        ...state,
        transportInfo: {
          ...state.transportInfo,
          contract: action.payload,
        },
      };

    case ActionTypes.SET_TRANSPORT_AMENITIES:
      return {
        ...state,
        transportInfo: {
          ...state.transportInfo,
          amenities: action.payload,
        },
      };

    case ActionTypes.SET_TRANSPORT_EMPLOYEES:
      return {
        ...state,
        transportInfo: {
          ...state.transportInfo,
          vendorEmployees: action.payload,
        },
      };

    case ActionTypes.SET_TRANSPORT_VEHICLES:
      console.log("SET_TRANSPORT_VEHICLES", action.payload);
      return {
        ...state,
        transportInfo: {
          ...state.transportInfo,
          vehicles: action.payload,
        },
      };

    // ***** END TRANSPORT VENDOR: STATE SET DATA *****//

    // ***** MEET GREET VENDOR: STATE SET DATA *****//

    case ActionTypes.SET_MEET_GREET_INFO:
      return {
        ...state,
        meetGreetInfo: action.payload,
      };

    case ActionTypes.SET_MEET_GREET_DETAILS:
      return {
        ...state,
        meetGreetInfo: {
          ...state.meetGreetInfo,
          meetGreetDetails: action.payload,
        },
      };

    case ActionTypes.SET_MEET_GREET_CONTRACT:
      return {
        ...state,
        meetGreetInfo: {
          ...state.transportInfo,
          contract: action.payload,
        },
      };

    case ActionTypes.SET_MEET_GREET_AMENITIES:
      return {
        ...state,
        meetGreetInfo: {
          ...state.transportInfo,
          amenities: action.payload,
        },
      };

    case ActionTypes.SET_MEET_GREET_EMPLOYEES:
      return {
        ...state,
        meetGreetInfo: {
          ...state.transportInfo,
          vendorEmployees: action.payload,
        },
      };

    case ActionTypes.SET_MEET_GREET_VEHICLES:
      return {
        ...state,
        meetGreetInfo: {
          ...state.transportInfo,
          vehicles: action.payload,
        },
      };

    // ***** END MEET GREET VENDOR: STATE SET DATA *****//

    case ActionTypes.SET_VENDOR_REQUEST_COUNT:
      return {
        ...state,
        vendorRequestCount: action.payload,
      };
    case ActionTypes.HIDE_LEFT_NAVBAR:
      return {
        ...state,
        hideLeftNavbar: action.payload,
      };
    case ActionTypes.RESET_STATE:
      return {
        ...state,
        hideLeftNavbar: false,
        hotelInfo: {
          hotelDetails: {},
          contacts: [],
          mealAllowance: [],
          rooms: [],
        },
      };

    default:
      return state;
  }
};

export const GlobalContextData = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState as any,
  dispatch: () => null,
});

export const GlobalContext = ({ children }: any) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalContextData.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContextData.Provider>
  );
};
