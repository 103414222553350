import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";

// Internal
import { ActionTypes, GlobalContextData } from "../context";
import { useDocumentTitle } from "./useDocumentTitle";
import { API } from "../constants";
import { ITab } from "../pages/VendorProfileDetails/VendorInfoTypes";
import { useGetService } from "./useGetService";
import { RoutePath } from "../routes";

const hotelTabsData: ITab[] = [
  {
    id: "profile",
    label: "Profile details",
    value: 0,
    completeStatus: false,
  },
  {
    id: "contact-information",
    label: "Contacts",
    value: 1,
    completeStatus: false,
  },
  {
    id: "room-projections",
    label: "Room Projections",
    value: 2,
    completeStatus: false,
  },
  {
    id: "meal-allowance",
    label: "Meal Allowance",
    value: 3,
    completeStatus: false,
  },
  {
    id: "amenities",
    label: "Amenities",
    value: 4,
    completeStatus: false,
  },
  {
    id: "employees",
    label: "Employees",
    value: 5,
    completeStatus: false,
  },
];

export const useHotelVendorProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Global store using context
  const { state, dispatch } = useContext(GlobalContextData);
  //const vendorType = state?.userInfo?.vendorType;

  useDocumentTitle("Hotel");

  const { response, isLoading, error } = useGetService(
    id && `${API.HOTEL.GET_HOTEL_DETAILS_BY_VENDORID}/${id}`
  );

  console.log("useHotelVendorProfile", state, response);
  const [tabs, setTabs] = useState(_.cloneDeep(hotelTabsData));

  const [tab, setTab] = useState<ITab>(tabs[0]);

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_LOADING,
      payload: {
        isLoading: isLoading,
        loadingText: "Please wait...",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (response && Object.keys(response).length > 0) {
      dispatch({
        type: ActionTypes.SET_HOTEL_INFO,
        payload: response,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    const data = [...tabs];
    if (state?.hotelInfo.hotelDetails?.id) {
      data[0].completeStatus = true;
    }
    if (state?.hotelInfo?.contacts?.length > 0) {
      data[1].completeStatus = true;
    }

    if (state?.hotelInfo?.rooms?.length > 0) {
      data[2].completeStatus = true;
    }
    if (
      state?.hotelInfo?.mealAllowanceDetails?.mealAllowance.length > 0 &&
      state?.hotelInfo?.mealAllowanceDetails?.hotelCuisine?.id
    ) {
      data[3].completeStatus = true;
    }
    if (
      state?.hotelInfo?.amenities &&
      state?.hotelInfo?.amenities?.length > 0
    ) {
      data[4].completeStatus = true;
    }
    if (
      state?.hotelInfo?.vendorEmployees &&
      state?.hotelInfo?.vendorEmployees.items &&
      state?.hotelInfo?.vendorEmployees.items.length > 0
    ) {
      data[5].completeStatus = true;
    }

    setTabs(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionTypes.SET_HOTEL_INFO,
        payload: {
          hotelDetails: {},
          contacts: [],
          mealAllowance: [],
          rooms: [],
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tabInfo = tabs.find(
      (tabData: ITab) => tabData.id === location.pathname.split("/")[2]
    );
    dispatch({
      type: ActionTypes.HIDE_LEFT_NAVBAR,
      payload: true,
    });
    tabInfo && setTab(tabInfo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleTabChange = (clickedTab: number) => {
    const tabInfo = tabs.find((tabData: ITab) => tabData.value === clickedTab);
    tabInfo && setTab(tabInfo);
    navigate(`/hotel/${tabInfo?.id}/${id}`);
  };

  const handleBack = () => {
    dispatch({
      type: ActionTypes.HIDE_LEFT_NAVBAR,
      payload: false,
    });
    navigate(RoutePath.DASHBOARD);
  };

  return {
    id,
    location,
    navigate,
    handleTabChange,
    handleBack,
    isLoading,
    error,
    tabs,
    tab,
  };
};
