export enum GroupIds {
  MANAGEMENT = "OPERATIONS",
  SETTINGS = "SETTINGS",
  SYSTEM_CONFIGURATION = "SYSTEM CONFIGURATION",
}

export enum RoutePath {
  // Auth :: path info
  APP_LANDING_PAGE = "/",
  DASHBOARD = "/dashboard",
  RESET_PASSWORD = "/reset-password",
  FORGOT_PASSWORD = "/forgot-password",
  FORGET_PASSWORD_CONFIRM_CODE = "/forgot-password-confirm-code",
  REGISTER_PASSKEY = "/register-passkey",

  // Vendor:: path info
  VENDOR_REGISTRATION = "/vendor-registration",
  EMPLOYEES = "/employees",
  ASSIGNMENT = "/assignment",
  INVOICING = "/invoicing",
  INVOICE_DETAILS = "/invoice-details",

  // HOTEL : TYPE VENDOR
  HOTEL_PROFILE_BY_ID = "/hotel/profile/:id",
  HOTEL_CONTACT_INFORMATION_BY_ID = "/hotel/contact-information/:id",
  HOTEL_AMENITIES_BY_ID = "/hotel/amenities/:id",
  HOTEL_MEAL_ALLOWANCE_BY_ID = "/hotel/meal-allowance/:id",
  HOTEL_ROOM_PROJECTIONS_BY_ID = "/hotel/room-projections/:id",
  HOTEL_EMPLOYEES_BY_ID = "/hotel/employees/:id",

  // TRANSPORT : TYPE VENDOR
  TRANSPORT_PROFILE_BY_ID = "/transport/profile/:id",
  TRANSPORT_CONTACT_INFORMATION_BY_ID = "/transport/contract-information/:id",
  TRANSPORT_AMENITIES_BY_ID = "/transport/amenities/:id",
  TRANSPORT_EMPLOYEE_BY_ID = "/transport/employees/:id",
  TRANSPORT_VEHICLE_BY_ID = "/transport/vehicles/:id",

  // MEET & GRRET : TYPE VENDOR
  MEET_GREET_PROFILE_BY_ID = "/meet-greet/profile/:id",
  MEET_GREET_CONTACT_INFORMATION_BY_ID = "/meet-greet/contract-information/:id",
  MEET_GREET_AMENITIES_BY_ID = "/meet-greet/amenities/:id",
  MEET_GREET_EMPLOYEE_BY_ID = "/meet-greet/employees/:id",
  MEET_GREET_VEHICLE_BY_ID = "/meet-greet/vehicles/:id",

  // TRANSPORT MEET & GRRET : TYPE VENDOR
  TRANSPORT_MEET_GREET_PROFILE_BY_ID = "/transportation-meet-greet/profile/:id",
  TRANSPORT_MEET_GREET_CONTACT_INFORMATION_BY_ID = "/transportation-meet-greet/contract-information/:id",
  TRANSPORT_MEET_GREET_AMENITIES_BY_ID = "/transportation-meet-greet/amenities/:id",
  TRANSPORT_MEET_GREET_EMPLOYEE_BY_ID = "/transportation-meet-greet/employees/:id",
  TRANSPORT_MEET_GREET_VEHICLE_BY_ID = "/transportation-meet-greet/vehicles/:id",

  //SUPER ADMIN or other :: path info
  SUPER_ADMIN_PROFILE = "/user-profile",
  SERVICE_REQUESTS = "/service-requests",
  VENDOR_REQUEST = "/vendor-requests",
  REGISTERED_VENDORS = "/registered-vendors",
  VENDOR_TYPE_CONFIG = "/vendor-type",
  PORT_CONFIG = "/ports",
  AIRPORT_CONFIG = "/airport",
  BRAND_CONFIG = "/brand",
  VEHICLE_CATEGORY_CONFIG = "/vehicle-category",
  AMENITIES_CONFIG = "/amenities",
  ALERTS = "/alerts",

  SETTINGS = "/settings",
}
